import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = { orderId: Number }

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: "CartPageChannel", order_id: this.orderIdValue },
      {
        received(data) {
          // Handle data received. For example, you can update the DOM here.
        }
      }
    );
  }

  disconnect() {
    if (this.channel) {
      this.channel.unsubscribe();
    }
  }
}
